import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { AppLayoutComponent } from './layout/app.layout.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { SmokeTestComponent } from './probe/smoketest/smoketest.component';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled'
};

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'system-administration',
        data: { breadcrumb: 'System Administration' },
        loadChildren: () => import('./support/components/system-administration/system-administration.module').then(m => m.SystemAdministrationModule)
      },
      {
        path: 'configuration',
        data: { breadcrumb: 'Configuration' },
        loadChildren: () => import('./support/components/configuration/configuration.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'notification',
        data: { breadcrumb: 'Notification' },
        loadChildren: () => import('./support/components/notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'api',
        data: { breadcrumb: 'API' },
        loadChildren: () => import('./support/components/api/api.module').then(m => m.ApiModule)
      },
      {
        // Needed for handling redirect after login
        path: 'auth',
        component: MsalRedirectComponent
      },
    ]
  },

  {
    path: 'probe/smoketest',
    component: SmokeTestComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
